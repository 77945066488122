import { variables } from '$lib/helpers/variables.js';
export const load = ({ fetch }) => {

    const fetchPosts = async () => {
        try {
            let res;

            res = await fetch(variables.publicSiteUrl + 'api/content', {
                method: 'GET',
                    credentials: 'same-origin',
                    redirect: 'follow',
                    agent: null,
                    headers: {
                    "Content-Type": "text/plain",
                        'Authorization': 'Basic ' + btoa(`${variables.httpUsername}:${variables.httpPassword}`),
                },
                timeout: 5000
            });

            const data = await res.json();
            return data;
        } catch (e) {
            console.error(e)
        }
    };

    return {
        content: fetchPosts()
    };
};
